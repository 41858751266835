exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-information-tsx": () => import("./../../../src/templates/information.tsx" /* webpackChunkName: "component---src-templates-information-tsx" */),
  "component---src-templates-print-tsx": () => import("./../../../src/templates/print.tsx" /* webpackChunkName: "component---src-templates-print-tsx" */),
  "component---src-templates-segment-id-tsx": () => import("./../../../src/templates/segment-[id].tsx" /* webpackChunkName: "component---src-templates-segment-id-tsx" */),
  "component---src-templates-task-id-tsx": () => import("./../../../src/templates/task-[id].tsx" /* webpackChunkName: "component---src-templates-task-id-tsx" */)
}

